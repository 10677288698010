@import "./../mixins/mixins";

.graphic-background-bottom-banner-illustration-cloudservers{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .graphic-background{
        &-left{
            margin-right: auto;
            min-width: max-content;
            @supports (-webkit-hyphens:none){
                height: intrinsic;
            }
        }
        &-right{
            margin-left: auto;
            min-width: max-content;
            @supports (-webkit-hyphens:none){
                height: intrinsic;
            }
        }
        &-center{
            min-width: max-content;
            overflow: visible;
            @supports (-webkit-hyphens:none){
                height: intrinsic;
            }
        }
    }
    &[data-animation-css]{
        &:not(.is-animated){
            [data-bg-element-animation],
            [data-server-animation-bottom],
            [data-server-animation-center],
            [data-server-animation-top],
            [data-server-animation-top-2],
            [data-server-sm-animation-bottom],
            [data-server-sm-animation-center],
            [data-server-sm-animation-top],
            [data-server-sm-animation-top-2],
            [data-animation-cube],
            [data-animation-coin],
            [data-animation-dot],
            [data-animation-path]{
                animation: unset!important
            }
        }
        &.is-paused{
            [data-animation-cube],
            [data-animation-coin],
            [data-animation-dot],
            [data-animation-vertical-loop-sm],
            [data-animation-vertical-loop],
            [data-animation-up-down],
            [data-animation-down-up] {
                // animation: unset!important
                animation-play-state: paused;
            }
        }
        [data-bg-element-animation],
        [data-server-animation-bottom],
        [data-server-animation-center],
        [data-server-animation-top],
        [data-server-animation-top-2],
        [data-server-sm-animation-bottom],
        [data-server-sm-animation-center],
        [data-server-sm-animation-top],
        [data-server-sm-animation-top-2],
        [data-animation-cube],
        [data-animation-dot],
        [data-animation-coin]{
            opacity: 0;
        }
        &.is-animated{ 
            [data-bg-element-animation]{
                animation-name: sectionBackgroundFadeTop0-cloudservers;
                animation-duration: 1s;
                animation-delay: 0s;
                animation-timing-function: ease-in;
                animation-fill-mode: forwards;
                &[data-animation-type-1]{
                    animation-name: sectionBackgroundFadeTop1-cloudservers;
                }
                &[data-animation-type-2]{
                    animation-name: sectionBackgroundFadeTop2-cloudservers;
                }
                &[data-animation-type-3]{
                    animation-name: sectionBackgroundFadeTop3-cloudservers;
                }
                &[data-animation-type-4]{
                    animation-name: sectionBackgroundFadeTop4-cloudservers;
                }
                &[data-animation-type-bottom-1]{
                    animation-name: sectionBackgroundFadeBottom1-cloudservers;
                }
                &[data-animation-type-bottom-2]{
                    animation-name: sectionBackgroundFadeBottom2-cloudservers;
                }
                &[data-animation-type-bottom-3]{
                    animation-name: sectionBackgroundFadeBottom3-cloudservers;
                }
                &[data-animation-type-bottom-4]{
                    animation-name: sectionBackgroundFadeBottom4-cloudservers;
                }
                &[data-animation-type-show]{
                    animation-name: serverAnimationBottom-cloudservers;
                    animation-delay: 1.72s;
                }
            }
            [data-server-animation]{
                animation-duration: 1s;
                animation-delay: 0s;
                animation-timing-function: cubic-bezier(0.16,0,0,1);
                animation-fill-mode: forwards;
                &[data-server-animation-bottom]{
                    animation-name: serverAnimationBottom-cloudservers;
                }
                &[data-server-animation-center]{
                    animation-name: serverAnimationCenter-cloudservers;
                }
                &[data-server-animation-top]{
                    animation-name: serverAnimationTop-cloudservers;
                }
                &[data-server-animation-top-2]{
                    animation-name: serverAnimationTop2-cloudservers;
                }
                &[data-server-sm-animation-bottom]{
                    animation-name: serverSmAnimationBottom-cloudservers;
                }
                &[data-server-sm-animation-center]{
                    animation-name: serverSmAnimationCenter-cloudservers;
                }
                &[data-server-sm-animation-top]{
                    animation-name: serverSmAnimationTop-cloudservers;
                }
                &[data-server-sm-animation-top-2]{
                    animation-name: serverSmAnimationTop2-cloudservers;
                }
            }
            [data-animation-dot]{
                animation-duration: 3.20s;
                animation-delay: 1.08s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
                animation-fill-mode: forwards;
                &[dot-opacity-up]{
                    animation-name: dotsLoopOpacity1-cloudservers;
                }
                &[dot-opacity-down]{
                    animation-name: dotsLoopOpacity2-cloudservers;
                }
            }
            [data-animation-cube]{
                will-change: transform, opacity;
                animation-name: cubeLoop-cloudservers;
                animation-duration: 2.20s;
                animation-delay: 1.08s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
                animation-fill-mode: forwards;
                &:nth-child(1){
                    animation-delay: 0.32s;
                }
                &:nth-child(2){
                    animation-delay: 0.96s;
                }
                &:nth-child(3){
                    animation-delay: 1.24s;
                }
                &[data-animation-cube-1]{
                    --cubeStart: 30px;
                    --cubeGap: -60px;
                }
                &[data-animation-cube-2]{
                    --cubeStart: 45px;
                    --cubeGap: -45px;
                }
                &[data-animation-cube-3]{
                    --cubeStart: 80px;
                    --cubeGap: 10px;
                }
            }
            [data-animation-coin]{
                will-change: transform, opacity;
                animation-name: coinLoop-cloudservers;
                animation-duration: 2.20s;
                animation-delay: 1.24s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
                animation-fill-mode: forwards;
                &:nth-child(1){
                    animation-delay: 0.32s;
                }
                &:nth-child(2){
                    animation-delay: 0.96s;
                }
                &:nth-child(3){
                    animation-delay: 1.56s;
                }
                &[data-animation-coin-1]{
                    --coinStart: 50px;
                    --coinGap: -50px;
                }
                &[data-animation-coin-2]{
                    --coinStart: 70px;
                    --coinGap: 0px;
                }
                &[data-animation-coin-3]{
                    --coinStart: 30px;
                    --coinGap: -90px;
                }
            }
            [data-animation-dot]{
                will-change: transform, opacity;
                animation-name: dotLoop-cloudservers;
                animation-duration: 2.20s;
                animation-delay: 1.24s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
                animation-fill-mode: forwards;
                &:nth-child(1){
                    animation-delay: 1.24s;
                }
                &:nth-child(2){
                    animation-delay: 0.32s;
                }
                &:nth-child(3){
                    animation-delay: 1.56s;
                }
                &:nth-child(4){
                    animation-delay: 0.56s;
                }
                &[data-animation-dot-1]{
                    --dotStart: 50px;
                    --dotGap: -20px;
                }
                &[data-animation-dot-2]{
                    --dotStart: 20px;
                    --dotGap: -30px;
                }
                &[data-animation-dot-3]{
                    --dotStart: 60px;
                    --dotGap: 0px;
                }
                &[data-animation-dot-4]{
                    --dotStart: 20px;
                    --dotGap: -20px;
                }
            }
            [data-animation-path]{
                will-change: transform, opacity;
                animation-timing-function: linear;
                animation-fill-mode: forwards;
                &[data-animation-path-1]{
                    animation-name: pathAnimation1-cloudservers;
                    animation-duration: 1s;
                    animation-delay: 1.08s;
                }
                &[data-animation-path-2]{
                    animation-name: pathAnimation2-cloudservers;
                    animation-duration: 1s;
                    animation-delay: 1.08s;
                }
                &[data-animation-path-3]{
                    animation-name: pathAnimation3-cloudservers;
                    animation-duration: 1s;
                    animation-delay: 1.08s;
                }
                &[data-animation-path-4]{
                    animation-name: pathAnimation4-cloudservers;
                    animation-duration: 1s;
                    animation-delay: 1.08s;
                }
                &[data-animation-path-5]{
                    animation-name: pathAnimation5-cloudservers;
                    animation-duration: 1s;
                    animation-delay: 2.08s;
                }
                &[data-animation-path-6]{
                    animation-name: pathAnimation6-cloudservers;
                    animation-duration: 1s;
                    animation-delay: 2.08s;
                }
                &[data-animation-path-7]{
                    animation-name: pathAnimation7-cloudservers;
                    animation-duration: 1s;
                    animation-delay: 1.08s;
                }
                &[data-animation-path-8]{
                    animation-name: pathAnimation8-cloudservers;
                    animation-duration: 1s;
                    animation-delay: 2.08s;
                }
                &[data-animation-path-9]{
                    animation-name: pathAnimation9-cloudservers;
                    animation-duration: 1s;
                    animation-delay: 2.08s;
                }
                &[data-animation-path-10]{
                    animation-name: pathAnimation10-cloudservers;
                    animation-duration: 1s;
                    animation-delay: 2.08s;
                }
                &[data-animation-path-11]{
                    animation-name: pathAnimation11-cloudservers;
                    animation-duration: 1s;
                    animation-delay: 2.08s;
                }
                &[data-animation-path-12]{
                    animation-name: pathAnimation12-cloudservers;
                    animation-duration: 1s;
                    animation-delay: 2.08s;
                }
                &[data-animation-path-13]{
                    animation-name: pathAnimation12-cloudservers;
                    animation-duration: 1s;
                    animation-delay: 1.08s;
                }
            }
            [data-animation-up-down]{
                will-change: transform, opacity;
                animation-name: upDownReverse-cloudservers;
                animation-duration: 3s;
                animation-delay: 1.08s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
                animation-fill-mode: forwards;
            }
            [data-animation-down-up]{
                animation-name: upDown-cloudservers;
                animation-duration: 3s;
                animation-delay: 1.08s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
                animation-fill-mode: forwards;
            }
            [data-animation-delay-1]{
                animation-delay: 0.56s;
            }
            [data-animation-delay-2]{
                animation-delay: 0.64s;
            }
            [data-animation-delay-3]{
                animation-delay: 0.80s;
            }
            [data-animation-delay-4]{
                animation-delay: 0.96s;
            }
            [data-animation-delay-5]{
                animation-delay: 1.96s;
            }
            [data-animation-delay-6]{
                animation-delay: 1.56s;
            }
            [data-animation-delay-7]{
                animation-delay: 2.36s;
            }
            [data-animation-delay-8]{
                animation-delay: 1.24s;
            }
            [data-animation-duration-0]{
                animation-duration: 0.48s;
            }
            [data-animation-duration-1]{
                animation-duration: 1s;
            }
            [data-animation-duration-2]{
                animation-duration: 2s;
            }
            [data-animation-duration-3]{
                animation-duration: 3s;
            }
        }
    }
    @keyframes sectionBackgroundFadeTop0-cloudservers {
        0%{
            transform: translate3d(0px, 0px , 0px); 
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            transform: translate3d(0px, 0px, 0px);
            opacity: 1;
        }
    }
    @keyframes sectionBackgroundFadeTop1-cloudservers {
        0%{
            transform: translate3d(0px, 16px, 0px); 
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            transform: translate3d(0px, 0px, 0px);
            opacity: 1;
        }
    }
    @keyframes sectionBackgroundFadeTop2-cloudservers {
        0%{
            transform: translate3d(0px, 32px, 0px); 
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            transform: translate3d(0px, 0px, 0px);
            opacity: 1;
        }
    }
    @keyframes sectionBackgroundFadeTop3-cloudservers {
        0%{
            transform: translate3d(0px, 48px, 0px); 
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            transform: translate3d(0px, 0px, 0px);
            opacity: 1;
        }
    }
    @keyframes sectionBackgroundFadeTop4-cloudservers {
        0%{
            transform: translate3d(0px, 56px, 0px); 
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            transform: translate3d(0px, 0px, 0px);
            opacity: 1;
        }
    }
    @keyframes sectionBackgroundFadeBottom1-cloudservers {
        0%{
            transform: translate3d(0px, -16px, 0px); 
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            transform: translate3d(0px, 0px, 0px);
            opacity: 1;
        }
    }
    @keyframes sectionBackgroundFadeBottom2-cloudservers {
        0%{
            transform: translate3d(0px, -32px, 0px); 
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            transform: translate3d(0px, 0px, 0px);
            opacity: 1;
        }
    }
    @keyframes sectionBackgroundFadeBottom3-cloudservers {
        0%{
            transform: translate3d(0px, -48px, 0px); 
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            transform: translate3d(0px, 0px, 0px);
            opacity: 1;
        }
    }
    @keyframes sectionBackgroundFadeBottom4-cloudservers {
        0%{
            transform: translate3d(0px, -56px, 0px); 
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            transform: translate3d(0px, 0px, 0px);
            opacity: 1;
        }
    }
    
    @keyframes serverAnimationBottom-cloudservers {
        0%{
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            opacity: 1;
        }
    }
    @keyframes serverAnimationCenter-cloudservers {
        0%{
            transform: translate3d(0px, 16px, 0px);
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            transform: translate3d(0px, 0px, 0px);
            opacity: 1;
        }
    }
    @keyframes serverAnimationTop-cloudservers {
        0%{
            transform: translate3d(0px, 32px, 0px);
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            transform: translate3d(0px, 0px, 0px);
            opacity: 1;
        }
    }
    @keyframes serverAnimationTop2-cloudservers {
        0%{
            transform: translate3d(0px, 48px, 0px);
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            transform: translate3d(0px, 0px, 0px);
            opacity: 1;
        }
    }
    
    @keyframes serverSmAnimationBottom-cloudservers {
        0%{
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            opacity: 1;
        }
    }
    
    @keyframes serverSmAnimationCenter-cloudservers {
        0%{
            transform: translate3d(0px, 8px, 0px);
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            transform: translate3d(0px, 0px, 0px);
            opacity: 1;
        }
    }
    @keyframes serverSmAnimationTop-cloudservers {
        0%{
            transform: translate3d(0px, 16px, 0px);
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            transform: translate3d(0px, 0px, 0px);
            opacity: 1;
        }
    }
    @keyframes serverSmAnimationTop2-cloudservers {
        0%{
            transform: translate3d(0px, 24px, 0px);
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            transform: translate3d(0px, 0px, 0px);
            opacity: 1;
        }
    }
    
    @keyframes dotsLoopOpacity1-cloudservers{
        0%, 100% {
            opacity: 0.2;
        }
        50% {
            opacity: 1;
        }
    }
    @keyframes dotsLoopOpacity2-cloudservers{
        0%, 100% {
            opacity: 1;
        }
        50% {
            opacity: 0.2;
        }
    }
    
    @keyframes cubeLoop-cloudservers {
        0% {
            transform: translate3d(0px, var(--cubeStart), 0px);
            opacity: 0
        }
    
        10%,60% {
            opacity: 1
        }
    
        to {
            transform: translate3d(0px, var(--cubeGap), 0px);
            opacity: 0
        }
    }
    @keyframes coinLoop-cloudservers {
        0% {
            transform: translate3d(0px, var(--coinStart), 0px);
            opacity: 0
        }
    
        10%,60% {
            opacity: 1
        }
    
        to {
            transform: translat3d(0px, var(--coinGap), 0px);
            opacity: 0
        }
    }
    @keyframes dotLoop-cloudservers {
        0% {
            transform: translate3d(0px, var(--dotStart), 0px);
            opacity: 0
        }
    
        10%,60% {
            opacity: 1
        }
    
        to {
            transform: translate3d(0px, var(--dotGap), 0px);
            opacity: 0
        }
    }
    
    @keyframes upDown-cloudservers {
        0%, 100% {
            transform: translate3d(0px, 0px, 0px);
        }
        50% {
            transform: translate3d(0px, 15px, 0px);
        }
    }
    @keyframes upDownReverse-cloudservers {
        0%, 100% {
            transform: translate3d(0px, 0px, 0px);
        }
        50% {
            transform: translate3d(0px, -15px, 0px);
        }
    }
    
    @keyframes pathAnimation1-cloudservers {from {stroke-dashoffset: 148;}to{stroke-dashoffset: 0;}}
    @keyframes pathAnimation2-cloudservers {from {stroke-dashoffset: 541;}to{stroke-dashoffset: 0;}}
    @keyframes pathAnimation3-cloudservers {from {stroke-dashoffset: 195;}to{stroke-dashoffset: 0;}}
    @keyframes pathAnimation4-cloudservers {from {stroke-dashoffset: 97;}to{stroke-dashoffset: 0;}}
    @keyframes pathAnimation5-cloudservers {from {stroke-dashoffset: 198;}to{stroke-dashoffset: 0;}}
    @keyframes pathAnimation6-cloudservers {from {stroke-dashoffset: 330;}to{stroke-dashoffset: 0;}}

    @keyframes pathAnimation7-cloudservers {from {stroke-dashoffset: 132;}to{stroke-dashoffset: 0;}}


    @keyframes pathAnimation8-cloudservers {from {stroke-dashoffset: 78;}to{stroke-dashoffset: 0;}}
    @keyframes pathAnimation9-cloudservers {from {stroke-dashoffset: 63;}to{stroke-dashoffset: 0;}}
    @keyframes pathAnimation10-cloudservers {from {stroke-dashoffset: -122;}to{stroke-dashoffset: 0;}}

    @keyframes pathAnimation11-cloudservers {from {stroke-dashoffset: 107;}to{stroke-dashoffset: 0;}}
    @keyframes pathAnimation12-cloudservers {from {stroke-dashoffset: -78;}to{stroke-dashoffset: 0;}}
    @keyframes pathAnimation13-cloudservers {from {stroke-dashoffset: 155;}to{stroke-dashoffset: 0;}}

}

